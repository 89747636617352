.bg-footer {
    /* background: url("./../images/common/bg-footer.png") no-repeat center center scroll; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
}

.bg-under-construction {
    /* background-image: url("./../images/common/under-construction.jpg"); */
    height: 60vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.under-construction-container {
    background-color: rgba(255, 255, 240, 0.8);
}

.jumb {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 300px;
}
.jumbotron-accommo {
    background: url("./../images/jumbotron/jumbotron-accommo.jpg") no-repeat center center scroll;
}
.jumbotron-photogallery {
    background: url("./../images/jumbotron/jumbotron-photogallery.jpg") no-repeat center center scroll;
}
.jumbotron-surroundings {
    background: url("./../images/jumbotron/jumbotron-surroundings.jpg") no-repeat center center scroll;
}
.jumbotron-pricelist {
    background: url("./../images/jumbotron/jumbotron-pricelist.jpg") no-repeat center center scroll;
}
.jumbotron-references {
    background: url("./../images/jumbotron/jumbotron-references.jpg") no-repeat center center scroll;
}
.jumbotron-contact {
    background: url("./../images/jumbotron/jumbotron-contact.jpg") no-repeat center center scroll;
}

.bg-page-doesnt-exist {
    /* background-image: url("./../images/common/404-not-found.jpg"); */
    height: 60vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.custom-btn {
	background-color: #799b1c;
	border-color: #799b1c;
}
.custom-btn:hover {
	background-color: #86ac1f;
	border-color: #799b1c;
}

.card-hover {
    transition: 0.2s ease;
}
.card-hover:hover {
    -webkit-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transform: scale(1.01);
    transition: 0.1s ease;
    -webkit-box-shadow: 0px 0px 10px 0px #658118;
    -moz-box-shadow: 0px 0px 10px 0px #658118;
    box-shadow: 0px 0px 10px 0px #658118;
    /* box-shadow: 0px 0px 30px -3px rgba(0, 0, 0, 0.54); */
}

.image-shadow {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.mapa {
    border: 0px;
    border-radius: 5px;
    width: 100%;
    height: 27vh;
}
.mapa-contact {
    height: 35vh;
}

.listgroup-header {
    background-color: lightgray;
}

.background-gray-dark {
    background-color: #373736;
    opacity: 0.9;
}