
@import url(http://fonts.googleapis.com/css?family=Roboto:300, 400, 700);
@import url(https://fonts.googleapis.com/css?family=Roboto + Condensed:300, 400, 700);
@import "~react-image-gallery/styles/css/image-gallery.css";

html,
body {
  font-family: "Roboto", sans-serif !important;
  /* font-family: 'Oxygen', sans-serif !important; */
  /* font-family: 'Dosis', sans-serif !important; */

  height: 100%;
  margin: 0;
}

.nav-big {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
  left: auto;
  right: 0px;
}

.nav-bg {
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 245, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 245, 1) 100%);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 245, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f5f5f5",GradientType=1);
  border-bottom: 1px solid #dee2e6 !important;
  -webkit-box-shadow: 0px 1px 2px 1px rgba(87, 85, 87, 0.15);
  -moz-box-shadow: 0px 1px 2px 1px rgba(87, 85, 87, 0.15);
  box-shadow: 0px 1px 2px 1px rgba(87, 85, 87, 0.15);
}

.nav-link:link {
  color: #373736;
}
.nav-link:visited {
  color: #373736;
}
.nav-link:hover {
  color: #799b1c;
}
.nav-link-active {
  color: #799b1c !important;
}

.text-center { text-align: center; }
.text-right { text-align: right; }
.text-left { text-align: left; }

.text-green { color: #a2d126; }
.text-dark-green { color: #799b1c; }
.text-light-gray { color: #f8f8f8; }
.text-gray { color: #575656; }
.text-dark-gray { color: #373736; }
.text-red { color: #9b1c31; }

.font-size-10 { font-size: 10px !important; }
.font-size-11 { font-size: 11px !important; }
.font-size-12 { font-size: 12px !important; }
.font-size-13 { font-size: 13px !important; }
.font-size-14 { font-size: 14px !important; }
.font-size-15 { font-size: 15px !important; }
.font-size-17 { font-size: 17px !important; }
.font-size-18 { font-size: 18px !important; }
.font-size-20 { font-size: 20px !important; }
.font-size-22 { font-size: 22px !important; }
.font-size-24 { font-size: 24px !important; }
.font-size-28 { font-size: 28px !important; }
.font-size-40 { font-size: 40px !important; }

.font-bold-3 { font-weight: 300; }
.font-bold-6 { font-weight: 600; }
.font-bold-9 { font-weight: 900; }


.shadow-text-sm {
  text-shadow: 5px 0px 5px rgba(0, 0, 0, 1);
}
.shadow-text-xs {
  text-shadow: 1px 1px 2px rgba(150, 150, 150, 0.76);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 1) !important;
}


.hyperlink:link {
  color: #799b1c;
}
.hyperlink:visited {
  color: #799b1c;
}
.hyperlink:hover {
  color: #799b1c;
}

.hyperlink-notunderline:link {
  color: #799b1c;
  text-decoration: none;
}
.hyperlink-notunderline:visited {
  color: #799b1c;
  text-decoration: none;
}
.hyperlink-notunderline:hover {
  color: #799b1c;
  text-decoration: none;
}

::ng-deep .mat-form-field {
  font-size: 12px;
}
