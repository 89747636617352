@import url(http://fonts.googleapis.com/css?family=Roboto:300, 400, 700);
@import url(https://fonts.googleapis.com/css?family=Roboto + Condensed:300, 400, 700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html,
body {
  font-family: "Roboto", sans-serif !important;
  /* font-family: 'Oxygen', sans-serif !important; */
  /* font-family: 'Dosis', sans-serif !important; */

  height: 100%;
  margin: 0;
}

.nav-big {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
  left: auto;
  right: 0px;
}

.nav-bg {
  font-size: 1.3rem !important;
  font-weight: 700 !important;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 245, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f5f5f5",GradientType=1);
  border-bottom: 1px solid #dee2e6 !important;
  box-shadow: 0px 1px 2px 1px rgba(87, 85, 87, 0.15);
}

.nav-link:link {
  color: #373736;
}
.nav-link:visited {
  color: #373736;
}
.nav-link:hover {
  color: #799b1c;
}
.nav-link-active {
  color: #799b1c !important;
}

.text-center { text-align: center; }
.text-right { text-align: right; }
.text-left { text-align: left; }

.text-green { color: #a2d126; }
.text-dark-green { color: #799b1c; }
.text-light-gray { color: #f8f8f8; }
.text-gray { color: #575656; }
.text-dark-gray { color: #373736; }
.text-red { color: #9b1c31; }

.font-size-10 { font-size: 10px !important; }
.font-size-11 { font-size: 11px !important; }
.font-size-12 { font-size: 12px !important; }
.font-size-13 { font-size: 13px !important; }
.font-size-14 { font-size: 14px !important; }
.font-size-15 { font-size: 15px !important; }
.font-size-17 { font-size: 17px !important; }
.font-size-18 { font-size: 18px !important; }
.font-size-20 { font-size: 20px !important; }
.font-size-22 { font-size: 22px !important; }
.font-size-24 { font-size: 24px !important; }
.font-size-28 { font-size: 28px !important; }
.font-size-40 { font-size: 40px !important; }

.font-bold-3 { font-weight: 300; }
.font-bold-6 { font-weight: 600; }
.font-bold-9 { font-weight: 900; }


.shadow-text-sm {
  text-shadow: 5px 0px 5px rgba(0, 0, 0, 1);
}
.shadow-text-xs {
  text-shadow: 1px 1px 2px rgba(150, 150, 150, 0.76);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 1) !important;
}


.hyperlink:link {
  color: #799b1c;
}
.hyperlink:visited {
  color: #799b1c;
}
.hyperlink:hover {
  color: #799b1c;
}

.hyperlink-notunderline:link {
  color: #799b1c;
  text-decoration: none;
}
.hyperlink-notunderline:visited {
  color: #799b1c;
  text-decoration: none;
}
.hyperlink-notunderline:hover {
  color: #799b1c;
  text-decoration: none;
}

::ng-deep .mat-form-field {
  font-size: 12px;
}

.bg-footer {
    /* background: url("./../images/common/bg-footer.png") no-repeat center center scroll; */
    background-size: cover;
    width: 100%;
}

.bg-under-construction {
    /* background-image: url("./../images/common/under-construction.jpg"); */
    height: 60vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.under-construction-container {
    background-color: rgba(255, 255, 240, 0.8);
}

.jumb {
    background-size: cover;
    width: 100%;
    height: 300px;
}
.jumbotron-accommo {
    background: url(/static/media/jumbotron-accommo.4a892de9.jpg) no-repeat center center scroll;
}
.jumbotron-photogallery {
    background: url(/static/media/jumbotron-photogallery.cb6c8dc6.jpg) no-repeat center center scroll;
}
.jumbotron-surroundings {
    background: url(/static/media/jumbotron-surroundings.7ce9f9f3.jpg) no-repeat center center scroll;
}
.jumbotron-pricelist {
    background: url(/static/media/jumbotron-pricelist.6c1222fb.jpg) no-repeat center center scroll;
}
.jumbotron-references {
    background: url(/static/media/jumbotron-references.a4f43041.jpg) no-repeat center center scroll;
}
.jumbotron-contact {
    background: url(/static/media/jumbotron-contact.3ad6a39c.jpg) no-repeat center center scroll;
}

.bg-page-doesnt-exist {
    /* background-image: url("./../images/common/404-not-found.jpg"); */
    height: 60vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.custom-btn {
	background-color: #799b1c;
	border-color: #799b1c;
}
.custom-btn:hover {
	background-color: #86ac1f;
	border-color: #799b1c;
}

.card-hover {
    transition: 0.2s ease;
}
.card-hover:hover {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    transition: 0.1s ease;
    box-shadow: 0px 0px 10px 0px #658118;
    /* box-shadow: 0px 0px 30px -3px rgba(0, 0, 0, 0.54); */
}

.image-shadow {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.mapa {
    border: 0px;
    border-radius: 5px;
    width: 100%;
    height: 27vh;
}
.mapa-contact {
    height: 35vh;
}

.listgroup-header {
    background-color: lightgray;
}

.background-gray-dark {
    background-color: #373736;
    opacity: 0.9;
}
